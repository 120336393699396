import { defineAsyncComponent, ref } from 'vue'
import EventBus from '@/classes/event-bus';

export default class SceneManager {
    static SCENES = [
        {
            name: 'entrance',
            component: defineAsyncComponent(() => import('@/components/scenes/Entrance.vue')),
        },
        {
            name: 'porch-main',
            component: defineAsyncComponent(() => import('@/components/scenes/PorchMain.vue')),
        },
        {
            name: 'porch-piano',
            component: defineAsyncComponent(() => import('@/components/scenes/PorchPiano.vue')),
        },
        {
            name: 'stage-pick-perspective',
            component: defineAsyncComponent(() => import('@/components/scenes/StagePickPerspective.vue'))
        },
        {
            name: 'stage-closed-curtain',
            component: defineAsyncComponent(() => import('@/components/scenes/StageClosedCurtain.vue'))
        },
        {
            name: 'stage-guest-view',
            component: defineAsyncComponent(() => import('@/components/scenes/StageGuestView.vue'))
        },
        {
            name: 'stage-actor-view',
            component: defineAsyncComponent(() => import('@/components/scenes/StageActorView.vue'))
        },
        {
            name: 'rehearsal-stage',
            component: defineAsyncComponent(() => import('@/components/scenes/RehearsalStage.vue'))
        },
        {
            name: 'changing-room',
            component: defineAsyncComponent(() => import('@/components/scenes/ChangingRoom.vue'))
        },
        {
            name: 'face-manipulation',
            component: defineAsyncComponent(() => import('@/components/games/FaceManipulation.vue'))
        },
        {
            name: 'tongue-twister',
            component: defineAsyncComponent(() => import('@/components/games/TongueTwister.vue'))
        },
        {
            name: 'fool-fight',
            component: defineAsyncComponent(() => import('@/components/games/FoolFight.vue'))
        },
        {
            name: 'piano',
            component: defineAsyncComponent(() => import('@/components/games/Piano.vue'))
        }
    ]

    static activeScene = ref(null);
    static scenesStack = [];

    static Initialize(startSceneName) {
        this.activeScene.value = this.GetSceneByName(startSceneName);
    }

    static GetActiveScene() {
        return this.activeScene.value;
    }

    static GetSceneByName(name) {
        return this.SCENES.find((e) => {
            return e.name == name;
        });
    }

    static TransitionToScene(name, ignore = false) {
        if (this.activeScene.value.name == name) {
            //scene is already active
            return;
        }

        if (!ignore) {
            this.scenesStack.push(this.activeScene.value.name);
        }

        EventBus.Broadcast(EventBus.CHANNELS.Scene, "transition", {scene: name});
        this.activeScene.value = this.GetSceneByName(name);
    }

    static GoBack() {
        if (!this.scenesStack.length) {
            return;
        }
        
        this.TransitionToScene(this.scenesStack.pop(), true);
    }

    static GetAllScenes() {
        return this.SCENES;
    }
}