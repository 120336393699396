import Cookies from 'js-cookie';
import {Howler} from 'howler';
import EventBus from '@/classes/event-bus';

export function toggleSound(v) {
    Howler.volume(v ? 1 : 0);
    Cookies.set('sound', v);
    EventBus.Broadcast(EventBus.CHANNELS.Global, "toggle-sound", {
        volume: v
    });
}

export function isSoundEnabled() {
    return Cookies.get('sound');
}