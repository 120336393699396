<template>
  <div class="ui-overlay">
    <!-- <LocalePicker /> -->
    <UISettings/>

    <div class="bottom-left-controls">
        <font-awesome-icon icon="home" size="2x" @click="SceneManager.TransitionToScene('entrance')"/>
    </div>

    <div class="bottom-center-wrapper">
        <div class="bottom-center">
            <div class="active-scene-display-name">
                <span class="go-back" @click="SceneManager.GoBack()">&larr;</span> {{ this.cms.get("translations").get(SceneManager.GetActiveScene().name) }}
            </div>
        </div>
    </div>

    <div class="bottom-right-controls">
        <a href="https://theaterdeslachens.de/de/impressum" target="_blank">Impressum</a>
        <a href="https://theaterdeslachens.de/de/datenschutz" target="_blank">Datenschutz</a>
    </div>

    <div class="arrow-controls">
        <div class="turn-l">
            <font-awesome-icon icon="chevron-circle-left" size="3x"/>
        </div>
        <div class="go-back">
            <font-awesome-icon icon="arrow-alt-circle-down" size="3x"/>
        </div>
        <div class="turn-r">
            <font-awesome-icon icon="chevron-circle-right" size="3x"/>
        </div>
    </div>
  </div>
</template>

<script>
// import LocalePicker from './LocalePicker.vue';
import UISettings from './UISettings.vue';
import SceneManager from "@/classes/scene-manager";

export default {
    inject: ['cms'],
  components: {
    // LocalePicker,
    UISettings
  },
  data() {
      return {
          SceneManager
      }
  }
};
</script>

<style lang="scss">
.ui-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;

    .bottom-left-controls {
        position: absolute;
        bottom: 15px;
        left: 10px;
        color: $hl-1;
        z-index: 1;

        svg {
            pointer-events: all;
            margin: 0 10px;
            cursor: pointer;
        }        
    }

    .bottom-right-controls {
        position: absolute;
        bottom: 15px;
        right: 10px;
        color: white;
        z-index: 1;

        a {
            color: white;
            font-size: 12px;
            pointer-events: all;

            &:first-child {
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid lightgray;
            }
        }
    }

    .bottom-center-wrapper {
        position: absolute;
        left: -1%;
        bottom: 0;
        width: 102%;
        display: flex;
        justify-content: center;

        .bottom-center {
            padding: 20px 20px;
            background-image: url("../assets/images/button2-bg.svg");
            background-repeat: no-repeat;
            background-size: cover;
            @extend .font-hl;
            width: 100%;
            padding-top: 30px;
            user-select: none;

            .active-scene-display-name {
                color: white;
                font-size: 36px;
                text-align: center;
                text-transform: lowercase;

                .go-back {
                    color: $hl-1;
                    cursor: pointer;
                    pointer-events: all;
                }
            }
        }
    }
    

    .arrow-controls {
        position: absolute;
        bottom: 10px;
        left: 50%;
        display: flex;
        justify-content: center;
        width: 400px;
        margin-left: -200px;
        display: none;

        .turn-l,
        .turn-r {
            margin-bottom: 200px;
        }

        .go-back {
            margin-top: 80px;
            margin-left: 100px;
            margin-right: 100px;
        }

        svg {
            pointer-events: all;
        }
    }
}

@media (max-width: 1000px) {
// @media (max-width: 1000px) and (max-height: 400px) {
    .ui-overlay {

        .bottom-left-controls {
            bottom: 5px;
            left: 10px;
            width: 30px;

            svg {
                margin: 0 0px;
                width: 100%;
            }        
        }

        .bottom-right-controls {
            bottom: 6px;
            right: 10px;

            a {
                font-size: 10px;
            }
        }

        .bottom-center-wrapper {

            .bottom-center {
                padding: 10px 20px;
                padding-top: 10px;

                .active-scene-display-name {
                    font-size: 25px;
                }
            }
        }
        

        .arrow-controls {
            bottom: 10px;
            left: 50%;
            width: 400px;
            margin-left: -200px;

            .turn-l,
            .turn-r {
                margin-bottom: 200px;
            }

            .go-back {
                margin-top: 80px;
                margin-left: 100px;
                margin-right: 100px;
            }

        }
    }
}
</style>