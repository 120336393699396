<template>
    <div class="ui-settings">
        <div class="toggle">
            <LocalePicker/>       

            <font-awesome-icon icon="volume-mute" size="2x" v-if="volume == false" @click="setGlobalVolume(true)"/>
            <font-awesome-icon icon="volume-up" size="2x" v-if="volume == true" @click="setGlobalVolume(false)"/>

            <font-awesome-icon icon="expand" size="2x" @click="toggleFullscreen"/>
        </div>
    </div>
</template>

<script>
import LocalePicker from "@/components/LocalePicker.vue";
import {toggleSound, isSoundEnabled} from '@/classes/helpers';
import EventBus from '@/classes/event-bus';

export default {
    components: {
        LocalePicker
    },
    mounted() {
        EventBus.Subscribe(EventBus.CHANNELS.Global, "toggle-sound", e => {
            this.volume = e.volume;
        });

        const vol = isSoundEnabled();
        if (vol == undefined) {
            this.volume = false;
        } else {
            if (vol == 'false') {
                this.volume = false;
            } else {
                this.volume = true;
            }
        }
    },
    data() {
        return {
            volume: false
        };
    },
    methods: {
        setGlobalVolume(vol) {
            toggleSound(vol);
            this.volume = vol;
        },
        toggleFullscreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                document.exitFullscreen();
                }
            }            
        }
    }
}
</script>

<style lang="scss">
    .ui-settings {
        .toggle {
            background-image: url("../assets/images/button-bg.svg");
            background-repeat: no-repeat;
            background-size: cover;            
            position: absolute;
            right: 0;
            top: -7px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            color: white;
            pointer-events: all;    

            .fa-volume-mute,
            .fa-volume-up {
                font-size: 1.5em;
                cursor: pointer;
                width: 1.125em;
            }

            .fa-expand {
                font-size: 1.5em;
                cursor: pointer;
                width: 1.125em;
                margin-top: 15px;
            }
        }
    }

// @media (max-width: 800px) and (max-height: 400px) {
@media (max-width: 1000px) {
    .ui-settings {
        .toggle {
            right: 0;
            top: -7px;
            padding: 15px 15px;

            .fa-volume-mute,
            .fa-volume-up {
                font-size: 20px;
                cursor: pointer;
                width: 20px;
            }

            .fa-expand {
                font-size: 20px;
                width: 20px;
                margin-top: 10px;
            }
        }
    }
}
</style>