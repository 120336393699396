export default class EventBus {
    static DEBUG = false;

    static CHANNELS = {
        Debug: 'Debug',
        Global: 'Global',
        Scene: 'Scene'
    };

    static eventHandlers = {};

    static Broadcast(channel, event, payload = {}) {
        if (this.CHANNELS[channel] == undefined) {
            throw `Unknown Channel for Broadcast on ${channel}/${event}`;
        }

        if (this.eventHandlers[channel] !== undefined && this.eventHandlers[channel][event] !== undefined) {
            for (let ref in this.eventHandlers[channel][event].handlers) {
                this.eventHandlers[channel][event].handlers[ref](payload);

                if (this.DEBUG) {
                    console.log(`Broadcasted ${channel}/${event} to Subscriber:${ref} with payload as:`, payload);
                }
            }
        }
    }

    static Subscribe(channel, event, handler) {
        if (this.eventHandlers[channel] == undefined) {
            this.eventHandlers[channel] = {};
        }

        if (this.eventHandlers[channel][event] == undefined) {
            this.eventHandlers[channel][event] = {
                nextRef: 0,
                handlers: {}
            };
        }

        let nextRef = this.eventHandlers[channel][event].nextRef;

        this.eventHandlers[channel][event].handlers[nextRef] = handler;
        this.eventHandlers[channel][event].nextRef = nextRef + 1;

        if (this.DEBUG) {
            console.log(`Subscribed Subscriber:${nextRef} to ${channel}/${event}`);
        }

        return {
            channel: channel,
            event: event,
            ref: nextRef,
            Unsubscribe() {
                EventBus.Unsubscribe(this.channel, this.event, this.ref);
            }
        }
    }

    static Unsubscribe(channel, event, ref) {
        if (this.DEBUG) {
            console.log(`Unsubscribed Subscriber:${ref} from ${channel}/${event}`);
        }

        delete this.eventHandlers[channel][event].handlers[ref];
    }
}