<template>
  <div class="locale-picker">
    <span class="current-locale" @click="nextLocale" :title="cms.getLocales()[cms.getLocale()]">{{ cms.getLocale() }}</span>
    <!-- <ul>
      <li
        v-for="(locale, localeCode) in cms.getLocales()"
        :key="localeCode"
        @click="cms.setLocale(localeCode)"
      >
        {{ locale }}
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  inject: {
    cms: {
      default: null,
    },
  },
  mounted() {},
  methods: {
    nextLocale() {
      const locales = [];
      for (let key in this.cms.getLocales()) {
        locales.push(key);
      }

      const idx = locales.indexOf(this.cms.getLocale()) + 1;

      if (idx > locales.length - 1) {
        this.cms.setLocale(locales[0]);
      } else {
        this.cms.setLocale(locales[idx]);
      }
    }
  }
};
</script>

<style lang="scss">
.locale-picker {
  .current-locale {
    @extend .font-hl;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    display: block;
    cursor: pointer;
    user-select: none;
  }  
}

@media (max-width: 800px) and (max-height: 400px) {
.locale-picker {
  .current-locale {
      font-size: 30px;
      line-height: 1em;
      margin-bottom: 10px;
    }  
  }
}
</style>