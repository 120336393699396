import { ref } from 'vue'
import EventBus from './event-bus';

export default class CMS {
    constructor() {

    }

    Initialize(data) {
        this.activeLocale = ref(data.locale.default);
        this.defaultLocale = data.locale.default;
        this.cmsData = data.cms;
        this.availableLocales = data.locale.availableLocales;
        this.paths = data.paths;
    }

    get(key, forceLocale = null) {
        let locale = this.getLocale();
        if (forceLocale != null) {
            locale = forceLocale;
        }

        let data = null;
        if (this.cmsData && this.cmsData[locale] && this.cmsData[locale][key]) {
            data = this.cmsData[locale][key];
        }

        return { 
            data,
            key,
            locale,
            get: (k) => {
                return (data ? data[k] : null) || `${locale}.${key}.${k}`;
            } 
        };
    }

    setLocale(locale) {
        this.activeLocale.value = locale;
        EventBus.Broadcast(EventBus.CHANNELS.Global, "locale-changed", {
            locale
        });
    }

    getLocale() {
        return this.activeLocale.value;
    }

    getLocales() {
        return this.availableLocales;
    }

    media(file) {
        return this.paths.media + "/" + file;
    }
}