import * as Vue from 'vue'
import App from './App.vue'
import EventBus from './classes/event-bus';
import { ref } from 'vue';
import CMS from './classes/cms';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// Font Awesome Setup
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { 
    faReply, 
    faHome,
    faCog,
    faChevronCircleLeft,
    faChevronCircleRight,
    faArrowAltCircleDown,
    faArrowLeft,
    faVolumeUp,
    faVolumeMute,
    faTrash,
    faExpand,
    faPlay,
    faPause
} from "@fortawesome/free-solid-svg-icons";

library.add([
    faReply, 
    faHome,
    faCog,
    faChevronCircleLeft,
    faChevronCircleRight,
    faArrowAltCircleDown,
    faArrowLeft,
    faVolumeUp,
    faVolumeMute,
    faTrash,
    faExpand,
    faPlay,
    faPause
]);

const app = 
    Vue.createApp(App)
        .provide('cms', new CMS())
        .component("font-awesome-icon", FontAwesomeIcon)
        .use(PerfectScrollbar)
        .mount('#app');

window.onYouTubeIframeAPIReady = function() {
    console.log('Ready');
};

var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);