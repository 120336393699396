<template>
    <BaseOverlay class="video-overlay" ref="baseOverlay" @hide="onHide" :dynamicHeight="true">
        <h3 class="title" v-if="title">{{ title }}</h3>
        <template v-if="mode == 'youtube'">
            <iframe v-if="videoSrc"
                width="560" 
                height="315" 
                :src="`https://www.youtube-nocookie.com/embed/${videoSrc}?enablejsapi=1`" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
                :ref="setYTRef"
            ></iframe>
        </template>
        <template v-if="mode == 'local'">
            <video ref="localVideoPlayer" controls width="560" height="315">
                <source :src="videoSrc" type="video/mp4">
                <p>Your browser doesn't support HTML5 video. Here is a <a :href="videoSrc">link to the video</a> instead.</p>
            </video>
        </template>
        <template v-if="mode == 'array'">
            <div class="video-slider">
                <div class="slider-prev" @click="moveSliderIndex(-1)">&laquo;</div>
                <div class="slider-slides">
                    <div v-for="(entry, index) in videoSrc" :key="entry.videoId" v-show="arrayIndex == index">
                        <template v-if="entry._group == 'youtube'">
                            <iframe v-if="videoSrc"
                                width="560" 
                                height="315" 
                                :src="`https://www.youtube-nocookie.com/embed/${entry.videoId}?enablejsapi=1`" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                                :ref="setYTRef"
                            ></iframe>
                        </template>
                        <template v-if="entry._group == 'local'">
                            <video ref="localVideoPlayer" controls width="560" height="315">
                                <source :src="cms.media(entry.file)" type="video/mp4">
                                <p>Your browser doesn't support HTML5 video. Here is a <a :href="cms.media(entry.file)">link to the video</a> instead.</p>
                            </video>
                        </template>
                    </div>
                </div>
                <div class="slider-next" @click="moveSliderIndex(+1)">&raquo;</div>
            </div>
        </template>
    </BaseOverlay>
</template>

<script>
import BaseOverlay from "@/components/BaseOverlay";
import EventBus from '@/classes/event-bus';
import { ref } from 'vue';
import { toggleSound } from '@/classes/helpers';
import {Howler} from 'howler';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseOverlay
    },
    data() {
        return {
            visible: false,
            videoSrc: "",
            mode: '',
            arrayIndex: 0,
            ytRefs: [],
            ytPlayers: [],
            title: null,
            initialVolume: 0
        }
    },
    setup() {
        const baseOverlay = ref(null);
        return {
            baseOverlay
        };
    },
    mounted() {
        EventBus.Subscribe(EventBus.CHANNELS.Global, "video-trigger", e => {
            this.videoSrc = e.url;
            this.title = e.title;
            this.mode = e.mode;
            this.onEnd = e.onEnd;
            this.onPlay = e.onPlay;
            this.onPause = e.onPause;
            this.onClose = e.onClose;
            this.ytRefs = [];
            this.ytPlayers = [];
            this.initialVolume = Howler.volume();
            this.$refs.baseOverlay.show();

            this.arrayIndex = 0;

            if (this.mode == 'local' || this.mode == 'array') {
                this.$nextTick(() => {
                    if (this.$refs.localVideoPlayer) {
                        this.$refs.localVideoPlayer.addEventListener("ended", e => {
                            this.$refs.baseOverlay.hide();
                            this.onEnd();
                            this.toggleSoundOptional(true);
                        });
        
                        this.$refs.localVideoPlayer.addEventListener("play", e => {
                            this.onPlay();
                            this.toggleSoundOptional(false);
                        });
        
                        this.$refs.localVideoPlayer.addEventListener("pause", e => {
                            this.onPause();
                            this.toggleSoundOptional(true);
                        });
                    }
                });
            }
        });
    },
    methods: {
        onHide() {
            this.videoSrc = '';
            this.mode = '';
            this.toggleSoundOptional(true);
            this.onClose();            
        },
        moveSliderIndex(direction) {
            if (direction == -1) {
                if (this.arrayIndex - 1 < 0) {
                    this.arrayIndex = this.videoSrc.length - 1;
                } else {
                    this.arrayIndex--;
                }
            } else {
                if (this.arrayIndex + 1 > this.videoSrc.length - 1) {
                    this.arrayIndex = 0;
                } else {
                    this.arrayIndex++;
                }                
            }

            for (const player of this.ytPlayers) {
                if (player.pauseVideo) {
                    player.pauseVideo();
                }
            }            
        },
        setYTRef(el) {
            console.log(el);
            if (el) {
                this.ytRefs.push(el);

                const player = new window.YT.Player(el, {
                    events: {
                        onStateChange: e => {
                            if (e.data == window.YT.PlayerState.PLAYING) {
                                this.toggleSoundOptional(false);
                            }

                            if (e.data == window.YT.PlayerState.PAUSED || e.data == window.YT.PlayerState.ENDED) {
                                this.toggleSoundOptional(true);
                            }
                        }
                    }
                });

                this.ytPlayers.push(player);
            }
        },
        toggleSoundOptional(v) {
            if (this.initialVolume == 0 && v) {
                return;
            }

            toggleSound(v);
        }
    }
}
</script>

<style lang="scss">
.video-overlay {
    .title {
        @extend .font-hl;
        font-size: 40px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .video-slider {
        display: flex;
        align-items: center;
        justify-content: center;            
        user-select: none;

        .slider-slides {
            margin: 0 20px;
        }

        .slider-prev,
        .slider-next {
            @extend .font-hl;
            font-size: 100px;
            cursor: pointer;
            transition: .35s;
    
            &:hover {
                color: $hl-1;
            }
        }    
    }
}
</style>