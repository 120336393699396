<template>
  <div class="app">
    <InitialLoader
      v-if="!initialLoaderGone"
      @ready="ready = true"
      @gone="onLoaderGone"
    />

    <VideoOverlay/>
    <RichTextOverlay/>

    <template v-if="ready">
      <UIOverlay />

      <component
        v-for="scene in SceneManager.GetAllScenes()"
        :key="scene.name"
        :is="scene.component"
      />
    </template>

    <div class="orientation-lock" v-if="ready && !deviceParamsOkay && !orientationLockDismissed">
      <div class="content">
        <h3>Achtung!</h3>
        <p>Ihr Bildschirm hat leider nicht die richtige Größe oder kein optimales Seitenverhältnis.</p>
        <p>Möglicherweise werden bestimmte Szenen nicht richtig dargestellt.</p>
        <button @click="orientationLockDismissed = true">Trotzdem versuchen</button>
      </div>
    </div>
  </div>
</template>

<script>
import InitialLoader from "./components/InitialLoader.vue";
import UIOverlay from "./components/UIOverlay.vue";
import axios from "axios";
import EventBus from "./classes/event-bus";
// import BaseScene from './components/BaseScene.vue';
// import StageFrontal from './components/scenes/StageFrontal.vue';
import SceneManager from "./classes/scene-manager";
import Cookies from 'js-cookie';
import VideoOverlay from './components/VideoOverlay.vue';
import RichTextOverlay from './components/RichTextOverlay.vue';
import ResourceManager from "./classes/resource-manager";

export default {
  inject: {
    cms: {
      default: null,
    },
  },
  components: {
    InitialLoader,
    UIOverlay,
    VideoOverlay,
    RichTextOverlay
  },  
  data() {
    return {
      ready: false,
      initialLoaderGone: false,
      SceneManager,
      deviceParamsOkay: false,
      orientationLockDismissed: false
    };
  },
  mounted() {
    SceneManager.Initialize("entrance");

    axios.get("https://spiegelstaette-data.theaterdeslachens.de/app-data").then((result) => {
    // axios.get("https://tdl-backend.blissmedia.de/app-data").then((result) => {
    // axios.get("http://tdl-webapp.local/app-data").then((result) => {
      this.cms.Initialize(result.data);

      ResourceManager.Initialize({
        images: {
          "scenes.entrance": require('@/assets/images/scene-entrance.jpg'),
          "scenes.porchmain": require('@/assets/images/scene-porch-main.jpg'),
          "scenes.porchpiano": require('@/assets/images/scene-porch-piano.jpg'),
          "scenes.rehearsalstage": require('@/assets/images/scene-rehearsal-stage.jpg'),
          "scenes.stageclosedcurtain": require('@/assets/images/scene-stage-closed-curtain.jpg'),
          "scenes.changingroom2": require('@/assets/images/scene-changing-room-2.jpg'),
          "scenes.stagepickperspective": require('@/assets/images/scene-stage-pick-perspective.jpg'),
          "scenes.facemanipulation": require('@/assets/images/scene-face-manipulation.jpg'),
          "scenes.tonguetwister": require('@/assets/images/scene-tongue-twister.jpg'),
          "scenes.tonguetwister-layer2": require('@/assets/images/tongue-twister-layer2.png'),
          "scenes.piano": require('@/assets/images/scene-piano.svg'),
          "scenes.foolfight": require('@/assets/foolfight/stage-bg.jpg'),
      
          "fool.sprite": require('@/assets/foolfight/characters/fool/sprite.png'),
          "fool.idle": require('@/assets/foolfight/characters/fool/idle.png'),
          "fool.walk": require('@/assets/foolfight/characters/fool/walk.png'),
          "fool.attack": require('@/assets/foolfight/characters/fool/attack.png'),        
      
          "crocodile.sprite": require('@/assets/foolfight/characters/crocodile/sprite.png'),
          "crocodile.idle": require('@/assets/foolfight/characters/crocodile/idle.png'),
          "crocodile.walk": require('@/assets/foolfight/characters/crocodile/walk.png'),
          "crocodile.attack": require('@/assets/foolfight/characters/crocodile/attack.png'),
      
          "princess.sprite": require('@/assets/foolfight/characters/princess/sprite.png'),
          "princess.idle": require('@/assets/foolfight/characters/princess/idle.png'),
          "princess.walk": require('@/assets/foolfight/characters/princess/walk.png'),
          "princess.attack": require('@/assets/foolfight/characters/princess/attack.png'),
      
          "granny.sprite": require('@/assets/foolfight/characters/granny/sprite.png'),
          "granny.idle": require('@/assets/foolfight/characters/granny/idle.png'),
          "granny.walk": require('@/assets/foolfight/characters/granny/walk.png'),
          "granny.attack": require('@/assets/foolfight/characters/granny/attack.png'),
      
          "policeman.sprite": require('@/assets/foolfight/characters/policeman/sprite.png'),
          "policeman.idle": require('@/assets/foolfight/characters/policeman/idle.png'),
          "policeman.walk": require('@/assets/foolfight/characters/policeman/walk.png'),
          "policeman.attack": require('@/assets/foolfight/characters/policeman/attack.png'),
      
          "devil.sprite": require('@/assets/foolfight/characters/devil/sprite.png'),
          "devil.idle": require('@/assets/foolfight/characters/devil/idle.png'),
          "devil.walk": require('@/assets/foolfight/characters/devil/walk.png'),
          "devil.attack": require('@/assets/foolfight/characters/devil/attack.png'),
  
          "fool.splash": require('@/assets/foolfight/characters/splashs/fool.png'),
          "crocodile.splash": require('@/assets/foolfight/characters/splashs/crocodile.png'),
          "devil.splash": require('@/assets/foolfight/characters/splashs/devil.png'),
          "granny.splash": require('@/assets/foolfight/characters/splashs/granny.png'),
          "princess.splash": require('@/assets/foolfight/characters/splashs/princess.png'),
          "policeman.splash": require('@/assets/foolfight/characters/splashs/policeman.png'),
      
          "stagemoods.off": require('@/assets/images/stage-moods/off.png'),
          "stagemoods.dark": require('@/assets/images/stage-moods/dark.jpg'),
          "stagemoods.swamp": require('@/assets/images/stage-moods/swamp.jpg'),
          "stagemoods.summer": require('@/assets/images/stage-moods/summer.jpg'),
          "stagemoods.winter": require('@/assets/images/stage-moods/winter.jpg'),
          "stagemoods.sunset": require('@/assets/images/stage-moods/sunset.jpg'),
          "stagemoods.night": require('@/assets/images/stage-moods/night.jpg'),
      
          "grandstandlights.1": require('@/assets/images/grandstand-lights/1.jpg'),
          "grandstandlights.2": require('@/assets/images/grandstand-lights/2.jpg'),
          "grandstandlights.3": require('@/assets/images/grandstand-lights/3.jpg'),
          
          "team.jpg.torsten": require('@/assets/images/team/torsten.jpg'),
          "team.jpg.arek": require('@/assets/images/team/arek.jpg'),
          "team.jpg.gundula": require('@/assets/images/team/gundula.jpg'),
          "team.jpg.andre": require('@/assets/images/team/andre.jpg'),
          "team.jpg.grit": require('@/assets/images/team/grit.jpg'),
          "team.jpg.mike": require('@/assets/images/team/mike.jpg'),
          "team.jpg.rodrigo": require('@/assets/images/team/rodrigo.jpg'),
          "team.jpg.nicole": require('@/assets/images/team/nicole.jpg'),
      
          "team.gif.torsten": require('@/assets/images/team/torsten.gif'),
          "team.gif.arek": require('@/assets/images/team/arek.gif'),
          "team.gif.gundula": require('@/assets/images/team/gundula.gif'),
          "team.gif.andre": require('@/assets/images/team/andre.gif'),
          "team.gif.grit": require('@/assets/images/team/grit.gif'),
          "team.gif.mike": require('@/assets/images/team/mike.gif'),
          "team.gif.rodrigo": require('@/assets/images/team/rodrigo.jpg'),
          "team.gif.nicole": require('@/assets/images/team/nicole.jpg'),        
      
          "misc.backstage": require('@/assets/images/backstage.png'),
          "misc.overlayright": require('@/assets/images/overlay-right.svg'),
  
          "facemanipulation.upload": require('@/assets/facemanipulation/button-upload.svg'),
          "facemanipulation.save": require('@/assets/facemanipulation/button-save.svg'),
          "facemanipulation.delete": require('@/assets/facemanipulation/button-delete.svg'),
          "facemanipulation.resize": require('@/assets/facemanipulation/resize.svg'),
          "facemanipulation.rotate": require('@/assets/facemanipulation/rotate.svg'),
          "facemanipulation.x-axis": require('@/assets/facemanipulation/x-axis.svg'),
          "facemanipulation.y-axis": require('@/assets/facemanipulation/y-axis.svg'),
  
          "tonguetwister.sprite.female": require('@/assets/images/tongue-twister-sprite.png'),
          "tonguetwister.sprite.male": require('@/assets/images/tongue-twister-sprite-male.png'),
  
          "piano.key.black": require('@/assets/images/key-black.svg'),
          "piano.key.white-c": require('@/assets/images/key-white-c.svg'),
          "piano.key.white-m": require('@/assets/images/key-white-m.svg'),
          "piano.key.white-l": require('@/assets/images/key-white-l.svg'),
          "piano.key.white-r": require('@/assets/images/key-white-r.svg'),

          //cms images
          "posters.1": this.cms.media(this.cms.get('entrance').get('poster_1')),
          "posters.2": this.cms.media(this.cms.get('entrance').get('poster_2'))
        },
        sounds: {
          "ambience.entrance": require('@/assets/audio/entrance-ambience.mp3'),
          "ambience.stagehall": require('@/assets/audio/ambience-stagehall.mp3'),
          "ambience.foyer": require('@/assets/audio/foyer-ambience.mp3'),
          "ambience.welcome": require('@/assets/audio/welcome.mp3'),
          "ambience.changingroom": require('@/assets/audio/ambience-changing-room.mp3'),
          "ambience.rehearsalstage": require('@/assets/audio/ambience-rehearsal-stage.mp3'),
  
          "shorts.witchylaugh": require('@/assets/audio/witchy-laugh.mp3'),
          "shorts.fanfare": require('@/assets/audio/fanfare.wav'),
          "shorts.footstepsshort": require('@/assets/audio/footsteps-short.mp3'),
          "shorts.footstepslong": require('@/assets/audio/footsteps-long.mp3'),
          "shorts.enginestart": require('@/assets/audio/engine-start.mp3'),
          "shorts.spotlight": require('@/assets/audio/spotlight.mp3'),
          "shorts.clockticking": require('@/assets/audio/clock-ticking.mp3'),
          "shorts.curtainopening": require('@/assets/audio/curtain-opening.mp3'),
          "shorts.cheer": require('@/assets/audio/cheer.mp3'),
          "shorts.boo": require('@/assets/audio/boo.mp3'),
          "shorts.ship-horn": require('@/assets/audio/ship-horn.mp3'),
  
          "shorts.fool.attack": require('@/assets/foolfight/characters/fool/attack.mp3'),
          "shorts.policeman.attack": require('@/assets/foolfight/characters/policeman/attack.mp3'),
          "shorts.princess.attack": require('@/assets/foolfight/characters/princess/attack.mp3'),
          "shorts.granny.attack": require('@/assets/foolfight/characters/granny/attack.mp3'),
          "shorts.crocodile.attack": require('@/assets/foolfight/characters/crocodile/attack.mp3'),
          "shorts.devil.attack": require('@/assets/foolfight/characters/devil/attack.mp3'),
  
          "laughs.andre.1": require('@/assets/audio/laughs/andre1.mp3'),
          "laughs.andre.2": require('@/assets/audio/laughs/andre2.mp3'),
          "laughs.andre.3": require('@/assets/audio/laughs/andre3.mp3'),
  
          "laughs.arek.1": require('@/assets/audio/laughs/arek1.mp3'),
          "laughs.arek.2": require('@/assets/audio/laughs/arek2.mp3'),
          "laughs.arek.3": require('@/assets/audio/laughs/arek3.mp3'),
          "laughs.arek.4": require('@/assets/audio/laughs/arek4.mp3'),
          "laughs.arek.5": require('@/assets/audio/laughs/arek5.mp3'),
  
          "laughs.grit.1": require('@/assets/audio/laughs/grit1.mp3'),
          "laughs.grit.2": require('@/assets/audio/laughs/grit2.mp3'),
          "laughs.grit.3": require('@/assets/audio/laughs/grit3.mp3'),
  
          "laughs.gundula.1": require('@/assets/audio/laughs/gundula1.mp3'),
          "laughs.gundula.2": require('@/assets/audio/laughs/gundula2.mp3'),
          "laughs.gundula.3": require('@/assets/audio/laughs/gundula3.mp3'),
          "laughs.gundula.4": require('@/assets/audio/laughs/gundula4.mp3'),
          "laughs.gundula.5": require('@/assets/audio/laughs/gundula5.mp3'),
          "laughs.gundula.6": require('@/assets/audio/laughs/gundula6.mp3'),
  
          "laughs.mike.1": require('@/assets/audio/laughs/mike1.mp3'),
          "laughs.mike.2": require('@/assets/audio/laughs/mike2.mp3'),
          "laughs.mike.3": require('@/assets/audio/laughs/mike3.mp3'),
          "laughs.mike.4": require('@/assets/audio/laughs/mike4.mp3'),
  
          "laughs.nicole.1": require('@/assets/audio/laughs/nicole1.mp3'),
          "laughs.nicole.2": require('@/assets/audio/laughs/nicole2.mp3'),
          "laughs.nicole.3": require('@/assets/audio/laughs/nicole3.mp3'),
          "laughs.nicole.4": require('@/assets/audio/laughs/nicole4.mp3'),
          "laughs.nicole.5": require('@/assets/audio/laughs/nicole5.mp3'),
          "laughs.nicole.6": require('@/assets/audio/laughs/nicole6.mp3'),        
          "laughs.nicole.7": require('@/assets/audio/laughs/nicole7.mp3'),
          "laughs.nicole.8": require('@/assets/audio/laughs/nicole8.mp3'),
          "laughs.nicole.9": require('@/assets/audio/laughs/nicole9.mp3'),        
          "laughs.nicole.10": require('@/assets/audio/laughs/nicole10.mp3'),        
          "laughs.nicole.11": require('@/assets/audio/laughs/nicole11.mp3'),        
  
          "laughs.rodrigo.1": require('@/assets/audio/laughs/rodrigo1.mp3'),
          "laughs.rodrigo.2": require('@/assets/audio/laughs/rodrigo2.mp3'),
          "laughs.rodrigo.3": require('@/assets/audio/laughs/rodrigo3.mp3'),
          "laughs.rodrigo.4": require('@/assets/audio/laughs/rodrigo4.mp3'),
  
          "laughs.torsten.1": require('@/assets/audio/laughs/torsten1.mp3'),
          "laughs.torsten.2": require('@/assets/audio/laughs/torsten2.mp3'),
          "laughs.torsten.3": require('@/assets/audio/laughs/torsten3.mp3'),
          "laughs.torsten.4": require('@/assets/audio/laughs/torsten4.mp3'),
          "laughs.torsten.5": require('@/assets/audio/laughs/torsten5.mp3'),
          "laughs.torsten.6": require('@/assets/audio/laughs/torsten6.mp3'),
  
          "laughsongs.arek": require('@/assets/audio/LACHSONG_AREK.mp3'),
          "laughsongs.nicole": require('@/assets/audio/LACHSONG_NICOLE.mp3'),
          "laughsongs.rodrigo": require('@/assets/audio/LACHSONG_RODRIGO.mp3'),
          "laughsongs.torsten": require('@/assets/audio/LACHSONG_TORSTEN.mp3'),
          "laughsongs.instrumental": require('@/assets/audio/LACHSONG.mp3'),
  
          "moods.dark": require('@/assets/audio/moods/dark.mp3'),
          "moods.swamp": require('@/assets/audio/moods/swamp.mp3'),
          "moods.summer": require('@/assets/audio/moods/summer.mp3'),
          "moods.winter": require('@/assets/audio/moods/winter.mp3'),
          "moods.sunset": require('@/assets/audio/moods/sunset.mp3'),
          "moods.night": require('@/assets/audio/moods/night.mp3'),
  
          "moods.soft": require('@/assets/audio/moods/soft.mp3'),
          "moods.medium": require('@/assets/audio/moods/medium.mp3'),
          "moods.strong": require('@/assets/audio/moods/strong.mp3'),
        }
      }, () => {        
        EventBus.Broadcast(EventBus.CHANNELS.Global, "initialized");
      });
    });    

  
    this.checkDeviceParameters();

    window.addEventListener("resize", () => {
      this.checkDeviceParameters();
    });
  },
  methods: {
    onLoaderGone() {
      this.initialLoaderGone = true;
    },
    checkDeviceParameters() {
      const w = document.documentElement.clientWidth;
      const aspectRatio = w / document.documentElement.clientHeight;
      
      this.deviceParamsOkay = (w >= 1024) && (aspectRatio >= 1.6) && (aspectRatio <= 2.2);
    }
  },
  computed: {
    data() {
      if (!this.ready) {
        return null;
      }

      return this.cms.get("page2");
    },
  },
};
</script>

<style lang="scss">
body {
  @extend .font-rt;
  background: black;
}

* {
  box-sizing: border-box;
}

.orientation-lock {
  background: red;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $main;
  color: white;

  .content {
    background: white;
    padding: 50px;
    text-align: center;

    h3 {
      @extend .font-hl;
      font-size: 50px;
      margin-top: 0;
      margin-bottom: 30px;
      color: $main;
    }

    p {
      color: black;
      margin: 0;
    }

    button {
      background: none;
      background-image: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="269.292px" height="70.866px" viewBox="0 0 269.292 70.866" xml:space="preserve" preserveAspectRatio="none"><path fill="%23063881" d="M232.732,3.918c-27.409-0.004-53.666-4.7-81.398-2.991c-26.062,1.607-52.176,0.209-78.258,0.152 C59.912,1.051,46.569,1.056,33.472,2.503C22.909,3.67,11.821-1.123,1.387,1.067c-0.515,5.64-2.205,66.811,0,66.698 c12.918-0.664,26.479,2.152,39.708,2.147c14.396-0.006,28.511-0.997,43.021-0.994c26.374,0.005,52.769-0.528,79.108,1.165 c20.428,1.313,41.254-2.167,62-2.168c14.478,0,28.582,2.656,42.86,1.872c1.333-20.414,1.124-42.277,0.023-62.721 C256.52,7.511,244.785,3.92,232.732,3.918"/></svg>');
      background-size: 100% 100%;
      transition: .35s;
      padding: 10px 20px;
      outline: 0;
      border: 0;
      color: white;
      margin: 0 10px;
      cursor: pointer;
      margin-top: 20px;
      font-weight: bold;
      font-size: 30px;
      @extend .font-hl;

      &:hover {
        background-image: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="269.292px" height="70.866px" viewBox="0 0 269.292 70.866" xml:space="preserve" preserveAspectRatio="none"><path fill="%2379b31e" d="M232.732,3.918c-27.409-0.004-53.666-4.7-81.398-2.991c-26.062,1.607-52.176,0.209-78.258,0.152 C59.912,1.051,46.569,1.056,33.472,2.503C22.909,3.67,11.821-1.123,1.387,1.067c-0.515,5.64-2.205,66.811,0,66.698 c12.918-0.664,26.479,2.152,39.708,2.147c14.396-0.006,28.511-0.997,43.021-0.994c26.374,0.005,52.769-0.528,79.108,1.165 c20.428,1.313,41.254-2.167,62-2.168c14.478,0,28.582,2.656,42.86,1.872c1.333-20.414,1.124-42.277,0.023-62.721 C256.52,7.511,244.785,3.92,232.732,3.918"/></svg>');
      }
    }
  }
}
</style>