import EventBus from '@/classes/event-bus';

export default class ResourceManager {
    static resources = {};
    static loaded = 0;
    static toLoad = 0;
    static gcs = [];

    static Initialize(data, done) {
        this.resources = {};
        this.toLoad = 0;
        this.loaded = 0;

        this.toLoad += Object.keys(data.images).length;
        this.toLoad += Object.keys(data.sounds).length;        

        for (const key in data.images) {
            const img = new Image();

            img.addEventListener("load", () => {
                this.loaded++;
                console.log("RL: " + this.loaded + "/" + this.toLoad);
                EventBus.Broadcast(EventBus.CHANNELS.Global, "loader", {cur: this.loaded, max: this.toLoad});

                if (this.loaded >= this.toLoad) {
                    if (done) {
                        done();
                        console.log("Completed resource loading: " + this.loaded + "/" + this.toLoad);
                    }
                }
            });

            img.src = data.images[key];
            this.resources[key] = img.src;
            this.gcs.push(img);
        }

        for (const key in data.sounds) {
            const snd = new Audio();

            snd.addEventListener("canplaythrough", () => {
                this.loaded++;
                console.log("RL: " + this.loaded + "/" + this.toLoad);
                EventBus.Broadcast(EventBus.CHANNELS.Global, "loader", {cur: this.loaded, max: this.toLoad});

                if (this.loaded >= this.toLoad) {
                    if (done) {
                        done();
                        console.log("Completed resource loading: " + this.loaded + "/" + this.toLoad);
                    }
                }
            });

            snd.src = data.sounds[key];
            this.resources[key] = snd.src;        
            this.gcs.push(snd); 
        }
    }

    static Get(name) {
        if (!(name in this.resources)) {
            throw `Resource with name ${name} has been requested but not registered`;
        }
        
        return this.resources[name];
    }
}