<template>
  <div class="initial-loader" :class="{fading: fading}">

    <template v-if="step == 1">
      <img src="@/assets/images/logo-theater-des-lachens.svg">
      <!-- TODO: Translate -->
      <p>Die Anwendung wird geladen. Bitte haben Sie einen Augenblick geduld.</p>
      <p style="margin-top: 0;">{{ cur }} / {{ max ? max : '?' }}</p>
    </template>

    <template v-if="step == 2">
      <div class="enable-sound">
        <h3>Möchten Sie den Ton aktivieren?</h3>
        <p>Sie können Ihre Entscheidung jederzeit überdenken.</p>
        <button @click="onSoundButtonFeedback(true)">Klar!</button>
        <button @click="onSoundButtonFeedback(false)">Lieber nicht</button>

      </div>
      
      <div class="bottom">
        <p>Dieses Projekt wurde gefördert durch</p>
        <div class="logos">        
          <img src="@/assets/images/sponsor-1.jpg">
          <img src="@/assets/images/sponsor-2.jpg">
          <img src="@/assets/images/sponsor-3.jpg">
          <img src="@/assets/images/sponsor-4.jpg">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EventBus from '@/classes/event-bus';
import {toggleSound, isSoundEnabled} from '@/classes/helpers';

export default {
  mounted() {
    EventBus.Subscribe(EventBus.CHANNELS.Global, "initialized", (data) => {
      setTimeout(() => {
        // if (isSoundEnabled() == undefined) {
          this.step = 2;
        // } else {
          // this.finish();
        // }
      }, 1500);
    });

    EventBus.Subscribe(EventBus.CHANNELS.Global, "loader", data => {
      this.cur = data.cur;
      this.max = data.max;
    });
  },
  data() {
    return {
      fading: false,
      skipTimeouts: false,
      step: 1,
      cur: 0,
      max: 0  
    };
  },
  methods: {
    onSoundButtonFeedback(result) {
      toggleSound(result);      
      this.finish();
    },
    finish() {
      this.$emit('ready');
      this.fading = true;

      setTimeout(() => {
        this.$emit('gone');
      }, this.skipTimeouts ? 0 : 1500);
    }
  }
}
</script>

<style lang="scss">
  .initial-loader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $main;
    flex-direction: column;
    transition: 1.5s;
    z-index: 1000;

    .bottom {
      background: white;
      position: absolute;
      width: 100%;
      padding: 20px;
      bottom: 0;

      p {
        color: black;
        text-align: center;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
      }

      .logos {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
  
        img {
          margin: 0 20px;
          height: 60px;
          animation: none;
        }
      }
    }


    &.fading {
      opacity: 0;
    }

    img {
      animation:bobbing 3.5s infinite;
    }

    p {
      color: white;
      margin-top: 30px;
    }

    .enable-sound {
      background: white;
      padding: 50px;
      text-align: center;

      h3 {
        @extend .font-hl;
        font-size: 50px;
        margin-top: 0;
        margin-bottom: 30px;
        color: $main;
      }

      p {
        color: black;
        margin: 0;
      }

      button {
        background: none;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="269.292px" height="70.866px" viewBox="0 0 269.292 70.866" xml:space="preserve" preserveAspectRatio="none"><path fill="%23063881" d="M232.732,3.918c-27.409-0.004-53.666-4.7-81.398-2.991c-26.062,1.607-52.176,0.209-78.258,0.152 C59.912,1.051,46.569,1.056,33.472,2.503C22.909,3.67,11.821-1.123,1.387,1.067c-0.515,5.64-2.205,66.811,0,66.698 c12.918-0.664,26.479,2.152,39.708,2.147c14.396-0.006,28.511-0.997,43.021-0.994c26.374,0.005,52.769-0.528,79.108,1.165 c20.428,1.313,41.254-2.167,62-2.168c14.478,0,28.582,2.656,42.86,1.872c1.333-20.414,1.124-42.277,0.023-62.721 C256.52,7.511,244.785,3.92,232.732,3.918"/></svg>');
        background-size: 100% 100%;
        transition: .35s;
        padding: 10px 20px;
        outline: 0;
        border: 0;
        color: white;
        margin: 0 10px;
        cursor: pointer;
        margin-top: 20px;
        font-weight: bold;
        font-size: 30px;
        @extend .font-hl;

        &:hover {
          background-image: url('data:image/svg+xml;utf8,<svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="269.292px" height="70.866px" viewBox="0 0 269.292 70.866" xml:space="preserve" preserveAspectRatio="none"><path fill="%2379b31e" d="M232.732,3.918c-27.409-0.004-53.666-4.7-81.398-2.991c-26.062,1.607-52.176,0.209-78.258,0.152 C59.912,1.051,46.569,1.056,33.472,2.503C22.909,3.67,11.821-1.123,1.387,1.067c-0.515,5.64-2.205,66.811,0,66.698 c12.918-0.664,26.479,2.152,39.708,2.147c14.396-0.006,28.511-0.997,43.021-0.994c26.374,0.005,52.769-0.528,79.108,1.165 c20.428,1.313,41.254-2.167,62-2.168c14.478,0,28.582,2.656,42.86,1.872c1.333-20.414,1.124-42.277,0.023-62.721 C256.52,7.511,244.785,3.92,232.732,3.918"/></svg>');
        }
      }
    }
  }

  @keyframes bobbing {
    0%, 100% {
      transform: scale(1.0);
    }

    50% {
      transform: scale(1.05);
    }
  }

@media (max-width: 800px) and (max-height: 400px) {

  .initial-loader {
    img {
      height: 50%;
      width: auto;
    }

    .enable-sound {
      margin-top: -120px;

      h3 {
        font-size: 30px;
      }
      
      button {
        font-size: 20px;
      }
    }

    .bottom {
      .logos {
        img {
          height: 30px;
        }
      }
    }
  }

}
</style>