<template>
    <div class="base-overlay" :class="{visible: visible}">
        <div class="inner" :style="{width: `${width}px`}" :class="{'dynamic-height': dynamicHeight, 'no-close-button': noCloseButton}">
            <div v-if="!noCloseButton" class="close-button" @click="hide"></div>
            <perfect-scrollbar :options="{swipeEasing: true}">
                <slot></slot>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    props: ['showOnInit', 'noCloseButton', 'width', 'dynamicHeight'],
    data() {
        return {
            visible: false
        };
    },
    mounted() {
        if (this.showOnInit) {
            this.show();
        }
    },
    methods: {
        show() {
            this.visible = true;
            this.$emit('show');
        },
        hide() {
            this.visible = false;
            this.$emit('hide');
        },
        toggle() {
            this.visible = !this.visible;
        }
    }
}
</script>

<style lang="scss">
.base-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 81;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .35s;
    padding: 20px;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .inner {
        background-image: url("../assets/images/button-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;   
        position: relative;
        padding: 40px;
        color: white;
        max-width: 100%;
        height: 100%;
        max-height: 500px;
        padding-right: 15px;
        padding-top: 60px;

        &.dynamic-height {
            max-height: unset;
            height: auto;
        }

        &.no-close-button {
            padding-top: 30px;
        }

        .ps {
            height: 100%;
            padding-right: 20px;

            .ps__rail-y {
                opacity: 0.9;
                width: 0;

                &:hover {
                    opacity: 1;
                }

                .ps__thumb-y {
                    width: 6px;
                    background: $hl-2;

                }
            }
        }

        .close-button {
            @extend .font-hl;
            color: white;
            position: absolute;
            right: 3px;
            top: 12px;
            cursor: pointer;
            padding-bottom: 3px;
            overflow: hidden;
            display: flex;

            &:after {
                content: '\00d7';
                font-size: 100px;

            }
        }
    }
}

@media (max-width: 800px) and (max-height: 400px) {

    .base-overlay {
        .inner {

            &.dynamic-height {
                max-height: 100vh;
                overflow-y: auto;
            }

            .close-button {
                top: 27px;
            }
        }
    }

}
</style>