<template>
    <BaseOverlay class="rich-text-overlay" ref="baseOverlay" :width="700">
        <span v-html="content"></span>
    </BaseOverlay>
</template>

<script>
import BaseOverlay from "@/components/BaseOverlay";
import EventBus from '@/classes/event-bus';
import { ref } from 'vue';

export default {
    components: {
        BaseOverlay
    },
    data() {
        return {
            visible: false,
            content: ""
        }
    },
    setup() {
        const baseOverlay = ref(null);
        return {
            baseOverlay
        };
    },
    mounted() {
        EventBus.Subscribe(EventBus.CHANNELS.Global, "rich-text-trigger", e => {
            this.content = e.content;
            this.$refs.baseOverlay.show();
        });
    }
}
</script>

<style lang="scss">
.rich-text-overlay {
    .inner {
        max-width: 900px;
    }
}
</style>